<template>
<div class="sidebar flex-grow-1 ">
	<div class="interior d-flex flex-column justify-content-between h-100 border-right">
		<div class="w-100">
			<div class="h5">
				Workspace
			</div>
			<b-nav class="primary" :class="{ active: primaryRouteActive }">
				<b-nav-item to="/library" active>
					<div class="d-flex align-items-center">
						<div class="material-icons-outlined md-light">dashboard</div>
						<div>
							Library
						</div>
					</div>
				</b-nav-item>
				<b-nav-item to="/collections">
					<div class="d-flex align-items-center">
						<div class="material-icons-outlined md-light">layers</div>
						<div>
							Collections
						</div>
					</div>
				</b-nav-item>
			</b-nav>
		</div>
		<div>
			<!-- <Ad type="carbon" cid="CK7I42JN" v-slot="{ ad }" show>
				<div class="ad rounded">
					<a :href="ad.statlink" target="_self" class="d-flex flex-column">
						<div class="logo-wrapper d-flex justify-content-center align-items-center" :style="{ 'background-color': ad.backgroundColor}">
							<img :src="ad.image" title="Ads By Carbon" class="d-block" />
						</div>
						<div class="w-100 d-flex flex-column">
							<div class="description">
								{{ ad.description }}
							</div>
							<div class="meta text-muted">
								Ads by Carbon
							</div>
						</div>
					</a>
				</div>
			</Ad> -->
			<Ad type="carbon" :key="$route.path" v-slot="{ ad }" show>
				<a class="helper rounded d-block" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_self" v-if="['custom', 'default'].includes(ad.rendering)">
					<div>
						<div class="d-inline-block logo-wrapper rounded p-3" :style="{ 'background-color': ad.backgroundColor}">
							<img :src="`${apiURL}/cdn/img/${base64Encode(ad.logo)}`" title="Sponsored By Carbon" class="d-block" style="max-width: 125px;" />
						</div>
					</div>
					<div class="w-100 d-flex flex-column">
						<div class="description">
							{{ ad.description }}
						</div>
						<div class="meta text-muted">
							Sponsored by Carbon
						</div>
					</div>
				</a>
				<a class="helper smaller rounded d-block" :href="`${apiURL}/cdn/r/${base64Encode(ad.statlink)}`" target="_blank" v-if="ad.rendering == 'carbon'">
					<div class="logo-wrapper">
						<img :src="`${apiURL}/cdn/img/${base64Encode(ad.smallImage)}`" title="Sponsored By Carbon" class="d-block rounded" style="max-width: 130px;" />
					</div>
					<div>
						<div class="description">
							{{ ad.description }}
						</div>
						<div class="meta text-muted">
							Sponsored by Carbon
						</div>
					</div>
				</a>
			</Ad>
			<router-link to="/pro" class="pro-badge d-flex align-items-center cursor-pointer" id="stats-popover" v-if="!isPro">
				<div class="icon d-flex align-items-center justify-content-center mr-3">
					<span class="material-icons-outlined text-white">bolt</span>
				</div>
				<div>
					<div class="title font-weight-bold mb-1">
						Upgrade Now
					</div>
					<div class="text-muted">
						Get Amino Pro
					</div>
				</div>
			</router-link>
			<div v-else>
				<div class="pro-badge d-flex align-items-center" id="stats-popover">
					<div class="icon d-flex align-items-center justify-content-center mr-3">
						<span class="material-icons-outlined text-white">bolt</span>
					</div>
					<div>
						<div class="title font-weight-bold mb-1">
							Amino Pro
						</div>
						<div class="text-muted">
							Valued Member
						</div>
					</div>
				</div>
				<!-- <b-popover target="stats-popover" triggers="hover" placement="bottom">
						<div class="h4 font-weight-bold mb-3">
							Your Insights
						</div>
						<div class="mb-2">
							<div class="text-muted">Stylesheets:</div>
							95
						</div>
						<div class="mb-2">
							<div class="text-muted">Collections:</div>
							10
						</div>
						<div class="mb-2">
							<div class="text-muted">Storage Used:</div>
							10mb
						</div>
						<div>
							<div class="text-muted">Membership:</div>
							2.99/mo
						</div>
					</b-popover> -->
			</div>
		</div>
	</div>
</div>
</template>

<script>
const Ad = () => import('@/components/Ad');
export default {
	name: 'SidebarWorkspace',
	components: {
		Ad
	},
	computed: {
		routeName() {
			return this.$route.name;
		},
		primaryRouteActive() {
			if (['library', 'collections'].includes(this.routeName)) {
				return true;
			}

			return false;
		}
	}
}
</script>

<style lang="scss" scoped>
.sidebar {
	position: fixed;
	z-index: 10;
	top: 116px;
	left: 0;
	bottom: 1.5rem;
	width: 260px !important;
	overflow-y: auto;
	@media (max-width: 900px) {
		display: none;
	}
	.interior {
		padding: 0 1.5rem;
		border-right-color: var(--color-700) !important;
	}
}

.ad-container {
	margin-top: 1.5rem;
}

.h5 {
	font-size: 0.813rem;
	color: var(--color-100);
	padding: 0 0 0 0.75rem;
}

.primary {
	flex-direction: column;
	margin-top: 1rem;

	.nav-link {
		letter-spacing: 0 !important;
		font-size: 1.25rem;
		font-weight: var(--font-weight-bold);
		border-radius: 0.5rem;
	}

	.material-icons-outlined {
		margin-right: 0.75rem;
	}

	a {
		color: var(--color-100);
		padding: 0.5rem 0.5rem 0.5rem 0.75rem;
		&.router-link-active {
			background-color: var(--color-700);
			color: var(--color-white);
		}
	}

	&:not(.active) {
		a {
			color: var(--color-white);
		}
	}
}

.upgrade-badge {
	margin-top: 1.5rem;
	a {
		font-size: 0.875rem;
		border-color: var(--color-500) !important;
		padding-left: 1rem !important;
		&:hover {
			background-color: var(--color-700);
			text-decoration: none;
		}
	}
}

.pro-badge {
	margin-top: 1.5rem;
	font-size: 0.75rem;
	.title {
		font-size: 0.85rem;
	}
	.icon {
		width: 2rem;
		height: 2rem;
		border-radius: 100%;
		background-color: var(--color-500);
		transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1);
		.material-icons-outlined {
			font-size: 1rem;
		}
	}
	&:hover {
		.icon {
			background-color: var(--color-primary);
			transform: scale(1.15);
		}
	}
}

.circle-arrow {
	width: 2rem;
	height: 2rem;
	border: 1px solid var(--color-500);
	border-radius: 2rem;
	.material-icons-outlined {
		font-size: 0.85rem;
	}
}

.helper {
	max-width: 480px;
	margin: 2rem auto 0;
	padding: 1.25rem;
	background-color: var(--color-500);
	.logo-wrapper {
		margin-bottom: 1rem;
		box-shadow: inset rgba(0, 0, 0, 0.05) 0 0 0 1px;
		border-radius: 4px;
		img {
			min-width: 3rem;
		}
	}
	.description {
		font-size: 0.8rem;
		line-height: 1.4;
	}
	.meta {
		font-size: 0.65rem;
		line-height: 1.4;
		margin-top: 0.5rem;
	}
	a {
		color: inherit;
		text-decoration: none;
		color: var(--color-text);
	}
	@media only screen and (max-width: 900px) {
		max-width: 240px;
	}
}
</style>
